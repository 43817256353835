<template>
  <div class="galery-page">
    <div class="galery">
      <div class="q-pa-md text-center text-h5 text-weight-bold">Galery SMP AFBS</div>        
      <div v-lazy-container="{ selector: 'img', error: './img/failed-load.png', loading: './img/loading.gif' }" class="img-container">
        <div  v-for="(x, i) in images" :key="i" class="img-list" @click="index = i">
          <img :data-src="x"  :alt="'galery-' + i" >
        </div>
      </div>
      <gallery :images="images" :index="index" @close="index = null"></gallery>
    </div>    
  </div>
</template>

<script>
// @ is an alias to /src
import VueGallery from 'vue-gallery';

export default {
  name: 'Home',
  components: {
    'gallery': VueGallery
  },
  data(){
    return{
      slide: 'style',
      lorem: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Itaque voluptatem totam, architecto cupiditate officia rerum, error dignissimos praesentium libero ab nemo.',
      index: null,
      images:[
        './img/galeri/galery-1.jpg',
        './img/galeri/galery-2.jpg',
        './img/galeri/galery-3.jpg',
        './img/galeri/galery-4.jpg',
        './img/galeri/galery-5.jpg',
        './img/galeri/galery-6.jpg',
        './img/galeri/galery-7.jpg',
        './img/galeri/galery-8.jpg',
        './img/galeri/galery-9.jpg',
        './img/galeri/galery-10.jpg',
        './img/galeri/galery-11.jpg',
        './img/galeri/galery-12.jpg',
        './img/galeri/galery-13.jpg',
        './img/galeri/galery-14.jpg',
        './img/galeri/galery-15.jpg',
      ]
    }
  },
  mounted(){

  },
  methods:{

  },
  computed:{
    maxHeight(){
      return this.$q.screen.height
    }
  }
}
</script>
<style lang="scss" scoped>

.galery{
  position: relative;
  padding-bottom: 2em;
  .galery-title{
    position: absolute;
    left: 50%; margin-left: -85px;
    background-color: #F2F5F6;
    width: 170px;
    font-weight: bold;
    text-align: center;
  }
  .img-container{
    width: 100%; display: flex; flex-wrap: wrap; justify-content: center;
  }
}
.img-list{
  width: 300px; max-width: 300px; min-width: 300px;
  height: 200px;
  overflow: hidden; position: relative;
  border-radius: 5px; margin: 10px;
}
.img-list img{
  width: 100%;
  height: 100%; object-fit: cover;
  transition: transform .7s, filter .7s ease-in-out;
  transform-origin: center center;
  filter: brightness(80%);
  &:hover{
    filter: brightness(100%);
    transform: scale(1.2);
  }
}
</style>
